import clsx from "clsx";
import React from "react";

type StyleProp = {
  className?: string;
  children: React.ReactNode;
  variant?: "default" | "primary" | "success" | "error";
  status?:
    | "in_progress"
    | "requested"
    | "pending"
    | "approved"
    | "denied"
    | "canceled"
    | "finished"
    | "billed"
    | "booked"
    | "completed";
};

const variants = {
  default: "bg-gray-100 text-[#74a128] border border-[#74a128]",
  primary: "bg-gray-100 text-[#0088FE] border border-[#0088FE]",
  success: "bg-gray-100 text-[#00C49F] border border-[#00C49F]",
  error: "bg-gray-100 text-[#c75b26] border border-[#c75b26]",
};

const statuses = {
  in_progress: "bg-white text-[#74a128] border font-bold border-[#74a128]",
  waiting_on_client:
    "bg-white text-[#ffae00] border font-bold border-[#74a128]",
  requested: "bg-white text-[#F0CA43] border font-bold border-[#F0CA43]",
  pending: "bg-white text-[#ffae00] border font-bold border-[#ffae00]",
  approved: "bg-white text-[#97c940] border font-bold border-[#97c940]",
  denied: "bg-white text-[#c75b26] border font-bold border-[#c75b26]",
  canceled:
    "bg-white text-black border font-bold border-black dark:border-white dark:text-white",
  finished: "bg-white text-[#0088FE] border font-bold border-[#0088FE]",
  billed: "bg-white text-[#0088FE] border font-bold border-[#0088FE]",
  booked: "bg-white text-[#74a128] border font-bold border-[#74a128]",
  completed: "bg-white text-[#74a128] border font-bold border-[#74a128]",
  postponed:
    "bg-white text-mainRed border font-bold border-mainRed dark:text-darkRed dark:border-darkRed",
};

export const Tag = ({ variant, className, children, status }: StyleProp) => {
  return (
    <span
      className={clsx(
        "text-[14px] px-2 py-[0.1rem] font-dmSans rounded-[3px] shadow-md dark:bg-darkGray",
        variant && `${variants[variant]}`,
        status && `${statuses[status]}`,
        className && className
      )}
    >
      {children}
    </span>
  );
};
